<template>
  <div class="page">
    <div class="router-list _mb-display-none">
      <div class="list">
        <div class="item" v-for="(item, index) in areaExpress" :key="index">
          <img class="pic" :src="item.logo" alt="" />
          <div class="info-box">
            <div class="title _pc-font-28">{{ item.area_express_name }}</div>
            <div class="_pc-mar-top-20 _pc-font-16" style="line-height: 1.46vw">
              {{ item.style }}
            </div>
            <div class="btn _pc-mar-top-30" @click="toEstimated">
              预估运费
              <img class="_pc-w-20 _pc-mar-left-10 right-ico-02" src="@/assets/image/right-ico-03.png" alt="" />
              <img class="_pc-w-20 _pc-mar-left-10 right-ico-hover" src="@/assets/image/right-ico-01.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="pagination _mb-display-none">
        <div class="btn" @click="lastPage">上一页</div>
        <div class="number-box">
          <div class="item" :class="{ active: index + 1 == page }" v-for="(item, index) in pageNum" :key="index"
            @click="changePage(index + 1)">
            {{ index + 1 }}
          </div>
        </div>
        <div class="btn" @click="nextPage">下一页</div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="_mb-display-block _pc-display-none">
      <div class="mb-router-list">
        <div class="item" v-for="(item, index) in areaExpress" :key="index">
          <img class="pic" :src="item.logo" alt="" />
          <div class="info-box">
            <div class="title _pc-font-28">{{ item.area_express_name }}</div>
            <div class="_pc-mar-top-20 _pc-font-16" style="line-height: 1.46vw">
              {{ item.style }}
            </div>
            <div class="btn _pc-mar-top-30" @click="toEstimated">
              预估运费
              <img class="_pc-w-20 _pc-mar-left-10 right-ico-02" src="@/assets/image/right-ico-03.png" alt="" />
              <img class="_pc-w-20 _pc-mar-left-10 right-ico-hover" src="@/assets/image/right-ico-01.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-pagination">
        <div class="btn last">
          <img class="icon" src="@/assets/image/right-ico-01-copy.png" alt="" />
          上一页
        </div>
        <div class="btn next">
          下一页
          <img class="icon" src="@/assets/image/right-ico-01-copy2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "routerList",
  data() {
    return {
      page: 1,
      limit: 10,
      areaExpress: [],
      count: 0,
      pageNum: 0
    };
  },
  created() {
    this.getAreaExpress()
  },
  methods: {
    getAreaExpress() {
      this.$axios.post('area_express', { page: this.page, limit: this.limit }).then(res => {
        this.areaExpress = res.data.data.list
        this.count = res.data.data.count
        this.pageNum = parseInt((this.count + this.limit - 1) / this.limit);
        console.log(this.count, 'count');

      })
    },
    toEstimated() {
      this.$router.push({
        path: "/estimated_price",
      });
    },

    changePage(page) {
      this.page = page;
      this.getAreaExpress()
    },

    lastPage() {
      if (this.page <= 1) {
        return;
      }
      this.page -= 1;
      this.getAreaExpress()
    },

    nextPage() {
      if (this.page >= this.pageNum) {
        return;
      }
      this.page += 1;
      this.getAreaExpress()
    },
  },
};
</script>

<style scoped>
.page {
  background: #fff;
}

.router-list {
  width: 72.92vw;
  margin: 0 auto;
}

.router-list .list {
  padding: 8.15vw 0 3.65vw;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.82vw;
  row-gap: 11vw;
}

.router-list .list .item {
  width: 100%;
  background-image: url("../../assets/image/router-item-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 2.08vw 1.82vw 2.08vw 15.63vw;
  position: relative;
}

.router-list .list .item .pic {
  position: absolute;
  bottom: 2.08vw;
  left: 1.56vw;
  width: 12.19vw;
  height: 17.29vw;
  object-fit: cover;
}

.router-list .list .item .info-box {
  width: 100%;
}

.router-list .list .item .info-box .title {
  color: #d92621;
}

.router-list .list .item .info-box .btn {
  width: 8.13vw;
  height: 2.81vw;
  border: 1px solid #d92621;
  font-size: 0.83vw;
  color: #d92621;
  line-height: 2.81vw;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.router-list .list .item .info-box .btn:hover {
  background: #d92621;
  color: #fff;
}

.router-list .list .item .info-box .btn:hover .right-ico-02 {
  display: block;
}

.router-list .list .item .info-box .btn:hover .right-ico-hover {
  display: none;
}

.right-ico-02 {
  display: none;
}

.right-ico-hover {
  display: block;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3.65vw;
}

.pagination .btn {
  width: 4.32vw;
  height: 1.72vw;
  border: 1px solid #d92621;
  border-radius: 0.89vw;
  font-size: 0.83vw;
  color: #d92621;
  line-height: 1.61vw;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
}

.pagination .btn:hover {
  background: #d92621;
  color: #fff;
}

.pagination .number-box {
  margin: 0 2.6vw;
  display: flex;
  align-items: center;
}

.pagination .number-box .item {
  width: 1.56vw;
  height: 1.56vw;
  font-size: 0.83vw;
  color: #333333;
  line-height: 1.56vw;
  text-align: center;
  border-radius: 50%;
  margin-right: 0.52vw;
  cursor: pointer;
}

.pagination .number-box .item:last-child {
  margin-right: 0;
}

.pagination .number-box .active {
  background: #d92621;
  color: #ffffff;
}

.mb-router-list {
  padding: 6.8vw 4vw 6.93vw 3.47vw;
}

.mb-router-list .item {
  width: 100%;
  background-image: url("../../assets/image/router-item-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 4.67vw 4.27vw 4.53vw 3.6vw;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4vw;
}

.mb-router-list .item:last-child {
  margin-bottom: 0;
}

.mb-router-list .item .pic {
  width: 31.33vw;
  height: 44.53vw;
  object-fit: cover;
}

.mb-router-list .item .info-box {
  flex: 1;
  padding-left: 4.67vw;
}

.mb-router-list .item .info-box .title {
  font-size: 5.33vw;
  color: #d92621;
}

.mb-router-list .item .info-box .content {
  font-size: 2.93vw;
  color: #333333;
  line-height: 4.93vw;
  margin-top: 3.2vw;
}

.mb-router-list .item .info-box .btn {
  width: 27.87vw;
  height: 8vw;
  background: #d92621;
  border: 1px solid #d92621;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.93vw;
  color: #ffffff;
  margin-top: 4vw;
}

.mb-router-list .item .info-box .btn .icon {
  width: 3.64vw;
  margin-left: 1.87vw;
}

.mb-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12vw;
}

.mb-pagination .btn {
  width: 27.87vw;
  height: 9.33vw;
  border: 0.27vw solid #d92621;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D92621;
  font-size: 2.93vw;
}

.mb-pagination .btn .icon {
  width: 3.73vw;
}

.mb-pagination .last {
  margin-right: 7.07vw;
}

.mb-pagination .last .icon {
  margin-right: 1.87vw;
}

.mb-pagination .next .icon {
  margin-left: 1.87vw;
}
</style>